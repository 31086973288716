import React from "react";
import styles from "../styles/general.module.scss";
import btnStyles from "../styles/btnStyles.module.scss";
import DOMPurify from "dompurify";

const VehicleInfo = ({
  darkMode,
  dealerId,
  clickUrlDealer,
  clickUrlModel,
  link,
  modelName,
  intro,
  features,
  seats,
  motorization,
  carBody,
  consumptionValues,
  price,
  utmContent,
}) => {
  const handleVehicleClick = () => {
    window.mobile.consent.helpers
      .getAnalyticsPermissions()
      .then(({ isAnalyticsAllowed }) => {
        if (isAnalyticsAllowed) {
          window.dataLayer.push({
            event: "analytics_event",
            attributes: {
              click_source_domain: document.referrer,
              event_action: "ShowVehiclesClick",
              event_category: "VehicleSection",
              event_label: "section=vehicle",
              non_interaction: false,
              page_type: "LandroverLandingPage",
              screen_name: "LandroverLandingPage",
            },
          });
        }
      });
  };

  const handleDATClick = () => {
    window.mobile.consent.helpers
      .getAnalyticsPermissions()
      .then(({ isAnalyticsAllowed }) => {
        if (isAnalyticsAllowed) {
          window.dataLayer.push({
            event: "analytics_event",
            attributes: {
              click_source_domain: document.referrer,
              event_action: "DatLinkClick",
              event_category: "VehicleSection",
              event_label: "section=vehicle",
              non_interaction: false,
              page_type: "LandroverLandingPage",
              screen_name: "LandroverLandingPage",
            },
          });
        }
      });
  };

  return (
    <div className={styles.vehicleContainer}>
      <div className={styles.vehicleContainer__inner}>
        <h1>{modelName}:</h1>
        <h2>Ihr perfektes Modell - direkt verfügbar!</h2>
        <p className={styles.introText}>{intro}</p>

        <p className={styles.introText}>
          Vereinbaren Sie noch heute Ihren{" "}
          <strong>persönlichen Beratungstermin.</strong> Entdecken Sie auf Sie
          zugeschnittene und sofort verfügbare Modelle zu attraktiven
          Konditionen.
        </p>

        <div className={styles.infoDetailsContainer}>
          <div className={styles.priceIndication}>
            <p>Direkt verfügbare Fahrzeuge</p>
            <p>Ab {price} €</p>
          </div>
          <div>
            <a
              href={`${
                dealerId
                  ? `${clickUrlDealer}&${clickUrlModel}&link=${link}&formCode=lr-raq-de-de&utm_source=mobile.de&utm_medium=link&utm_campaign=kooperation_mobile.de_24&utm_content=${utmContent}`
                  : `https://www.landrover-formulare.de/angebote/stock-locator/?formCode=lr-raq-de-de=&ref_type=landingpage&dealerNumber=%20&${clickUrlModel}&link=${link}&formCode=lr-raq-de-de&utm_source=mobile.de&utm_medium=link&utm_campaign=kooperation_mobile.de_24&utm_content=${utmContent}`
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className={btnStyles.actionButton}
                onClick={() => handleVehicleClick()}
              >
                <div>
                  INDIVIDUELLE BERATUNG SICHERN!<sup>1</sup>
                </div>
              </button>
            </a>
            <p className={`${styles.bottomInfo} ${styles.buttonSpacing}`}>
              <sup className={styles.supSmall}>1</sup>Sie werden direkt auf das
              Hersteller-Formular weitergeleitet.
            </p>
          </div>
        </div>

        <div className={styles.specs}>
          {carBody !== "" ? (
            <div className={styles.specsRow}>
              <div className={styles.specsCaption}>
                {darkMode ? (
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/icon_carBody_grey.svg"
                    }
                    alt="Karosserieform"
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/images/icon_carBody.svg"}
                    alt="Karosserieform"
                  />
                )}

                <span>Karosserieform</span>
              </div>
              <p>{carBody}</p>
            </div>
          ) : null}

          {features !== "" ? (
            <div className={styles.specsRow}>
              <div className={styles.specsCaption}>
                {darkMode ? (
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/icon_ausstattung_grey.svg"
                    }
                    alt="Ausstattungsvarianten"
                  />
                ) : (
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/icon_ausstattung.svg"
                    }
                    alt="Ausstattungsvarianten"
                  />
                )}

                <span>Ausstattungsvarianten</span>
              </div>
              <p>{features}</p>
            </div>
          ) : null}

          {seats !== "" ? (
            <div className={styles.specsRow}>
              <div className={styles.specsCaption}>
                {darkMode ? (
                  <img
                    src={process.env.PUBLIC_URL + "/images/icon_sitze_grey.svg"}
                    alt="Sitzplätze"
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/images/icon_sitze.svg"}
                    alt="Sitzplätze"
                  />
                )}
                <span>Sitzplätze</span>
              </div>
              <p>{seats}</p>
            </div>
          ) : null}

          {motorization !== "" ? (
            <div className={styles.specsRow}>
              <div className={styles.specsCaption}>
                {darkMode ? (
                  <img
                    src={process.env.PUBLIC_URL + "/images/icon_motor_grey.svg"}
                    alt="Motorenvarianten"
                  />
                ) : (
                  <img
                    src={process.env.PUBLIC_URL + "/images/icon_motor.svg"}
                    alt="Motorenvarianten"
                  />
                )}
                <span>Motorenvarianten</span>
              </div>
              <p>{motorization}</p>
            </div>
          ) : null}
        </div>

        <div className={styles.additionalInfo}>
          <p>Verbrauchs- und Emissionswerte:</p>
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(consumptionValues),
            }}
          ></p>
          <p>
            Weitere Informationen und DAT-Hinweis auf{" "}
            <a
              href="https://www.landrover.de/download-a-brochure/dat.html"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleDATClick()}
            >
              landrover.de/dat
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VehicleInfo;
