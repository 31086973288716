import React, { useState, useEffect, useRef } from "react";
import styles from "../styles/general.module.scss";
import btnStyles from "../styles/btnStyles.module.scss";

const DealerInfo = ({
  dealerId,
  dealership,
  dealerAddress,
  modelName,
  contact1,
  clickUrlDealer,
  clickUrlModel,
  link,
  utmMedium,
  utmSource,
  utmCampaign,
  utmTerm,
  utmContent,
}) => {
  const specificRef = useRef(null);
  const otherRef = useRef(null);

  const [dealershipHeight, setDealershipHeight] = useState(0);

  useEffect(() => {
    // Get the height
    if (specificRef.current) {
      const height = specificRef.current.clientHeight;
      setDealershipHeight(height);
    }
  }, [specificRef.current]);

  useEffect(() => {
    // Assign the height
    if (otherRef.current && dealershipHeight !== 0) {
      otherRef.current.style.height = `${dealershipHeight}px`;
    }
  }, [dealershipHeight]);

  // const [inputValue, setInputValue] = useState("");

  // const handleInputChange = (event) => {
  //   setInputValue(event.target.value);
  // };

  // const handleSearchDealer = () => {
  //   window.open(
  //     `https://fahrzeugsuche-landrover.de/de/haendlersuche?action=send&ZIP_LOC=${inputValue}&RADIUS_LEN=100#gmaps`,
  //     "_blank"
  //   );
  // };

  const handleVehicleClick = () => {
    window.mobile.consent.helpers
      .getAnalyticsPermissions()
      .then(({ isAnalyticsAllowed }) => {
        if (isAnalyticsAllowed) {
          window.dataLayer.push({
            event: "analytics_event",
            attributes: {
              click_source_domain: document.referrer,
              event_action: "ShowVehiclesClick",
              event_category: "DealerSection",
              event_label: "section=dealer",
              non_interaction: false,
              page_type: "LandroverLandingPage",
              screen_name: "LandroverLandingPage",
            },
          });
        }
      });
  };

  const handleDealerClick = () => {
    window.mobile.consent.helpers
      .getAnalyticsPermissions()
      .then(({ isAnalyticsAllowed }) => {
        if (isAnalyticsAllowed) {
          window.dataLayer.push({
            event: "analytics_event",
            attributes: {
              click_source_domain: document.referrer,
              event_action: "SearchDealerClick",
              event_category: "DealerSection",
              event_label: "section=dealer",
              non_interaction: false,
              page_type: "LandroverLandingPage",
              screen_name: "LandroverLandingPage",
            },
          });
        }
      });
  };

  return (
    <>
      <div className={styles.dealerContainer}>
        <div className={styles.dealerContainer__inner}>
          <h2 className={styles.biggerH2}>
            Persönliche Beratung vereinbaren und perfektes Modell finden!
          </h2>
          <p className={styles.introText}>
            Der {modelName} ist ab sofort direkt bei Ihrem Händler vor Ort
            verfügbar. Informieren Sie sich jetzt über die vielfältigen
            Ausstattungsvarianten und finden Sie Ihr persönliches
            Traum-Fahrzeug.
          </p>
          <h2>So einfach gehts:</h2>
          <ol style={{ marginBottom: "32px" }}>
            <li>
              <strong>Termin vereinbaren:</strong> Vereinbaren Sie jetzt direkt
              über das Kontaktformular einen Telefontermin mit Ihrem Händler vor
              Ort.
            </li>
            <li>
              <strong>Informieren lassen:</strong> Lassen Sie sich umfassend zu
              den verfügbaren Fahrzeugen beraten.
            </li>
            <li>
              <strong>Losfahren:</strong> Erleben Sie Ihr Wunschmodell hautnah.
            </li>
          </ol>
          <h2>Ihre Vorteile:</h2>
          <ol style={{ marginBottom: "64px" }}>
            <li>
              <strong>Vielseitig:</strong> Wir bieten die passende Ausstattung
              für Ihre Bedürfnisse.
            </li>
            <li>
              <strong>Direkt verfügbar:</strong> Ihr Wunschmodell wartet auf Sie
              und ist direkt verfügbar!
            </li>
          </ol>
          {dealerId ? (
            <>
              <div className={styles.dealerInfo}>
                <div ref={specificRef} className={styles.specificDealer}>
                  <h3>{dealership}</h3>
                  <div className={styles.specificDealerContact}>
                    <p>{dealerAddress}</p>
                    <p>{contact1}</p>
                  </div>
                  <div>
                    <a
                      href={`${clickUrlDealer}&${clickUrlModel}&link=${link}&formCode=lr-raq-de-de&utm_source=mobile.de&utm_medium=link&utm_campaign=kooperation_mobile.de_24&utm_content=${utmContent}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className={btnStyles.actionButton}
                        onClick={() => handleVehicleClick()}
                      >
                        <div>
                          INDIVIDUELLE BERATUNG SICHERN!<sup>1</sup>
                        </div>
                      </button>
                    </a>
                    <p
                      className={`${styles.bottomInfo} ${styles.buttonSpacing}`}
                    >
                      <sup className={styles.supSmall}>1</sup>Sie werden direkt
                      auf das Hersteller-Formular weitergeleitet.
                    </p>
                  </div>
                </div>

                <div className={styles.dealerLine}></div>

                <div ref={otherRef} className={styles.otherDealer}>
                  <h3>
                    Finden Sie hier weitere teilnehmende Händler in Ihrer Nähe:
                  </h3>
                  <div>
                    <a
                      href={`https://www.landrover-formulare.de/angebote/stock-locator/?formCode=lr-raq-de-de=&ref_type=landingpage&dealerNumber=%20&${clickUrlModel}&link=${link}&formCode=lr-raq-de-de&utm_source=mobile.de&utm_medium=link&utm_campaign=kooperation_mobile.de_24&utm_content=${utmContent}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className={btnStyles.actionButton}
                        onClick={() => handleDealerClick()}
                      >
                        <div>
                          HÄNDLER VOR ORT FINDEN!<sup>1</sup>
                        </div>
                      </button>
                    </a>
                    <p
                      className={`${styles.bottomInfo} ${styles.buttonSpacing}`}
                    >
                      <sup className={styles.supSmall}>1</sup>Sie werden direkt
                      auf das Hersteller-Formular weitergeleitet.
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.centeredDealerInfo}>
                <h2>Finden Sie hier einen Händler in Ihrer Nähe</h2>
                <div>
                  <a
                    href={`https://www.landrover-formulare.de/angebote/stock-locator/?formCode=lr-raq-de-de=&ref_type=landingpage&dealerNumber=%20&${clickUrlModel}&link=${link}&formCode=lr-raq-de-de&utm_source=mobile.de&utm_medium=link&utm_campaign=kooperation_mobile.de_24&utm_content=${utmContent}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      className={btnStyles.actionButton}
                      onClick={() => handleDealerClick()}
                    >
                      <div>
                        HÄNDLER VOR ORT FINDEN!<sup>1</sup>
                      </div>
                    </button>
                  </a>
                  <p className={`${styles.bottomInfo} ${styles.buttonSpacing}`}>
                    <sup className={styles.supSmall}>1</sup>Sie werden direkt
                    auf das Hersteller-Formular weitergeleitet.
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <p className={styles.bottomInfo}>
          * Verbrauchswerte nach §2 Nrn. 5, 6, 6a Pkw-ENVKV in der jeweils
          geltenden Fassung liegen nicht vor. NEFZ-Daten nicht verfügbar. Der
          Gesetzgeber arbeitet an einer Novellierung der Pkw-ENVKV und empfiehlt
          in der Zwischenzeit für Fahrzeuge, die nicht mehr auf Grundlage des
          Neuen Europäischen Fahrzyklus (NEFZ) homologiert werden können die
          Angabe der realitätsnäheren WLTP- Werte.
        </p>
      </div>
    </>
  );
};

export default DealerInfo;
