import React, { useState } from "react";
import styles from "../styles/general.module.scss";
import btnStyles from "../styles/btnStyles.module.scss";

const Header = ({ darkMode }) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerContainer__inner}>
        <div className={styles.focusPoint}>
          <a
            href="https://www.mobile.de/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={process.env.PUBLIC_URL + "/images/mobile.svg"}
              className={styles.logo}
              alt="Mobile.de Logo"
            />
          </a>
          <p className={styles.claim}>Deutschlands größter Fahrzeugmarkt</p>
        </div>

        <div className={`${styles.focusPoint} ${styles.navigation}`}>
          <a
            href="https://suchen.mobile.de/fahrzeuge/search.html?dam=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Suchen</span>
          </a>
          <a
            href="https://www.mobile.de/verkaufen/auto/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Verkaufen</span>
          </a>
          <a
            href="https://www.mobile.de/magazin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Informieren</span>
          </a>
        </div>

        <div className={`${styles.focusPoint} ${styles.account}`}>
          {/* <img
            src={process.env.PUBLIC_URL + "/images/notification.svg"}
            alt="Notifications"
          />
          <img
            src={process.env.PUBLIC_URL + "/images/search.svg"}
            alt="Suche"
          />
          <img
            src={process.env.PUBLIC_URL + "/images/park.svg"}
            alt="Parkplatzzeichen"
          /> */}
          <a
            href="https://id.mobile.de/login?service=https%3A%2F%2Fid.mobile.de%2Foauth2.0%2FcallbackAuthorize%3Fclient_id%3Dmobile_web_DL1WJUPw%26redirect_uri%3Dhttps%253A%252F%252Fwww.mobile.de%252Fapi%252Fauth%252FloginCallback%253F%26response_type%3Dcode%26response_mode%3Dquery%26client_name%3DCasOAuthClient&lang=de&state=eyJybmQiOiJLWER6emFMeV9ONU5hWEpnbnRkNWEwZXBuWlhiVWdkdDBzaTJxNzV6V0NZIiwic3JjIjoiaHR0cHM6Ly93d3cubW9iaWxlLmRlLyJ9&nonce=1bXVZ-O_jwIuLAJEE7BppZPACPJMu3HgyTVupg6bMLU&scope=openid"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className={btnStyles.anmelden}>Anmelden</button>
          </a>
        </div>
        <img
          className={styles.navMenu}
          src={process.env.PUBLIC_URL + "/images/navmenu.svg"}
          alt="Menü"
          onClick={() => toggleMenu()}
        />
      </div>

      <div
        className={`${styles.mobileMenu} ${showMenu ? styles.showMenu : null}`}
      >
        <button className={styles.closeButton} onClick={() => toggleMenu()}>
          Menü
          {darkMode ? (
            <img
              src={process.env.PUBLIC_URL + "/images/navClose_grey.svg"}
              alt="Navigation schließen"
            />
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/images/navClose.svg"}
              alt="Navigation schließen"
            />
          )}
        </button>
        <a
          href="https://suchen.mobile.de/fahrzeuge/search.html?dam=0"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className={styles.mobileMenuItem}>Suchen</p>
        </a>
        <a
          href="https://www.mobile.de/verkaufen/auto/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className={styles.mobileMenuItem}>Verkaufen</p>
        </a>
        <a
          href="https://www.mobile.de/magazin/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className={styles.mobileMenuItem}>Informieren</p>
        </a>
        <a
          href="https://id.mobile.de/login?service=https%3A%2F%2Fid.mobile.de%2Foauth2.0%2FcallbackAuthorize%3Fclient_id%3Dmobile_web_DL1WJUPw%26redirect_uri%3Dhttps%253A%252F%252Fwww.mobile.de%252Fapi%252Fauth%252FloginCallback%253F%26response_type%3Dcode%26response_mode%3Dquery%26client_name%3DCasOAuthClient&lang=de&state=eyJybmQiOiJLWER6emFMeV9ONU5hWEpnbnRkNWEwZXBuWlhiVWdkdDBzaTJxNzV6V0NZIiwic3JjIjoiaHR0cHM6Ly93d3cubW9iaWxlLmRlLyJ9&nonce=1bXVZ-O_jwIuLAJEE7BppZPACPJMu3HgyTVupg6bMLU&scope=openid"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className={btnStyles.anmelden}>Anmelden</button>
        </a>
      </div>
    </div>
  );
};

export default Header;
