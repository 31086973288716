import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../styles/general.module.scss";
import "../styles/carousel-dots.css";
import { Modal } from "react-bootstrap";
import CloseIcon from "../assets/navClose.svg";
import CloseIconGrey from "../assets/navClose_grey.svg";

const Impressions = ({ images, modelIndex, darkMode, modelName }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(null);

  useEffect(() => {
    if (darkMode) {
      const activeIndicator = document.querySelector(
        ".carousel-indicators .active"
      );
      activeIndicator.classList.add("activeWhite");
    }
  }, [darkMode]);

  const handleImageClick = (index) => {
    setShowModal(true);
    setModalImageIndex(index);
  };

  return (
    <>
      <div className={styles.impressionsContainer}>
        <div className={styles.impressionsContainer__inner}>
          <span className={styles.adHint}>WERBUNG</span>
          <div className={styles.impressionsGallery}>
            <div className={styles.impressionsFullWidth}>
              <img
                src={process.env.PUBLIC_URL + images["0"]}
                alt="Land Rover model"
                className={`
                ${
                  modelIndex === "1" || modelIndex === "2" || modelIndex === "3"
                    ? styles.positionBottom
                    : null
                } ${modelIndex === "4" ? styles.positionLightTop : null} ${
                  modelIndex === "5" ? styles.positionSportLarge : null
                }
              `}
                onClick={() => handleImageClick(0)}
              />
            </div>

            <div className={styles.impressionsContainer__bottomRow}>
              <div className={`${styles.impressions15} ${styles.flexImage}`}>
                <img
                  src={process.env.PUBLIC_URL + images["1"]}
                  alt="Land Rover model"
                  onClick={() => handleImageClick(1)}
                />
              </div>
              <div className={`${styles.impressions21} ${styles.flexImage}`}>
                <img
                  src={process.env.PUBLIC_URL + images["2"]}
                  alt="Land Rover model"
                  onClick={() => handleImageClick(2)}
                />
              </div>
              <div className={`${styles.impressions28} ${styles.flexImage}`}>
                <img
                  src={process.env.PUBLIC_URL + images["3"]}
                  alt="Land Rover model"
                  onClick={() => handleImageClick(3)}
                />
              </div>
              <div className={`${styles.impressionsRest} ${styles.flexImage}`}>
                <img
                  src={process.env.PUBLIC_URL + images["4"]}
                  alt="Land Rover model"
                  onClick={() => handleImageClick(4)}
                />
              </div>
            </div>
          </div>
          <div className={styles.impressionsCarousel}>
            <Carousel
              interval={null}
              nextIcon={
                <div className={styles.nextIconContainer}>
                  <span className={styles.nextIcon}></span>
                </div>
              }
              prevIcon={
                <div className={styles.nextIconContainer}>
                  <span className={styles.prevIcon}></span>
                </div>
              }
              className={`${darkMode ? "darkmodeCarousel" : ""}`}
              onSlide={() => {
                const activeIndicator = document.querySelector(
                  ".carousel-indicators .active"
                );
                activeIndicator.classList.add("activeWhite");
              }}
            >
              <Carousel.Item>
                <img
                  src={process.env.PUBLIC_URL + images["0"]}
                  className={styles.carouselImage}
                  alt="Land Rover model"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={process.env.PUBLIC_URL + images["1"]}
                  className={styles.carouselImage}
                  alt="Land Rover model"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={process.env.PUBLIC_URL + images["2"]}
                  className={styles.carouselImage}
                  alt="Land Rover model"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={process.env.PUBLIC_URL + images["3"]}
                  className={styles.carouselImage}
                  alt="Land Rover model"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={process.env.PUBLIC_URL + images["4"]}
                  className={styles.carouselImage}
                  alt="Land Rover model"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header className={`${darkMode ? "darkModal" : null}`}>
          <Modal.Title className={`${darkMode ? "darkModalColor" : null}`}>
            {modelName}
          </Modal.Title>
          <button
            type="button"
            className="close"
            onClick={() => setShowModal(false)}
          >
            <img src={darkMode ? CloseIconGrey : CloseIcon} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className={`${darkMode ? "darkModal" : null}`}>
          <img
            src={process.env.PUBLIC_URL + images[modalImageIndex]}
            alt="Land Rover model"
            className={styles.modalImage}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Impressions;
