import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import styles from "./styles/general.module.scss";
import btnStyles from "./styles/btnStyles.module.scss";
import Impressions from "./components/Impressions";
import VehicleInfo from "./components/VehicleInfo";
import DealerInfo from "./components/DealerInfo";
import dealers from "./components/data/dealers.json";
import models from "./components/data/models.json";

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [modelIndex, setModelIndex] = useState(2);
  const [dealerIndex, setDealerIndex] = useState(null);

  // model
  const [modelName, setModelName] = useState("Discovery");
  const [intro, setIntro] = useState(
    "Überaus komfortabel. Überaus geräumig. Überaus familientauglich. Der Discovery ist sicher einer der vielseitigsten SUV weltweit und begeistert mit seiner leistungsstarken Geländetauglichkeit ebenso wie mit seiner flexiblen 7-Sitzkonfiguartion."
  );
  const [features, setFeatures] = useState("S, Dynamic SE und Dynamic HSE");
  const [seats, setSeats] = useState("5 oder 7");
  const [motorization, setMotorization] = useState("Diesel oder Benziner");
  const [carBody, setCarBody] = useState("");
  const [consumptionValues, setConsumptionValues] = useState(
    'Discovery D250 AWD S 3.0 Liter: Kraftstoffverbrauch und CO<span class="lower2">2</span>-Emissionen nach WLTP* (jeweils max. komb.): 8,6 l/100 km; 224 g/km.\nDiscovery P360 AWD 3.0 Liter: Kraftstoffverbrauch und CO<span class="lower2">2</span>-Emissionen nach WLTP* (jeweils max. komb.): 11,0 l/100 km; 247 g/km.\nDiscovery D300 AWD 3.0 Liter: Kraftstoffverbrauch und CO<span class="lower2">2</span>-Emissionen nach WLTP* (jeweils max. komb.): 8,7 l/100 km; 228 g/km.'
  );
  const [price, setPrice] = useState("84.036");
  const [images, setImages] = useState({
    0: "/images/discovery/L46224GL_303101655_012.jpg",
    1: "/images/discovery/1180544_L462_23MY_010_GLHD.png",
    2: "/images/discovery/L55024GL_303101775_053.png",
    3: "/images/discovery/L46224GL_303101655_046.png",
    4: "/images/discovery/L46224GL_303101655_032.png",
  });

  // dealer
  const [dealership, setDealership] = useState(
    "Auto Nagel M\u00fcnster GmbH & Co. KG"
  );
  const [dealerAddress, setDealerAddress] = useState(
    "K\u00f6lner Strasse 2, 48163 M\u00fcnster"
  );
  const [contact1, setContact1] = useState("0251 / 6093304 0");
  const [clickUrlDealer, setClickUrlDealer] = useState("");

  const [clickUrlModel, setClickUrlModel] = useState("");

  // url parameter
  const [utmMedium, setUtmMedium] = useState("");
  const [utmSource, setUtmSource] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmTerm, setUtmTerm] = useState("");
  const [utmContent, setUtmContent] = useState("");

  const [link, setLink] = useState("");

  // effect for checking dark mode
  useEffect(() => {
    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setDarkMode(darkModeQuery.matches);

    const updateDarkMode = (e) => {
      setDarkMode(e.matches);
    };

    darkModeQuery.addListener(updateDarkMode);

    return () => {
      darkModeQuery.removeListener(updateDarkMode);
    };
  }, []);

  // effect for getting data
  useEffect(() => {
    // get params
    const urlParams = new URLSearchParams(window.location.search);
    const dealerId = urlParams.get("dealerId");
    const modelId = urlParams.get("modelId");

    const utm_medium = urlParams.get("utm_medium");
    const utm_source = urlParams.get("utm_source");
    // const utm_campaign = urlParams.get("utm_campaign");
    // const utm_term = urlParams.get("utm_term");
    // const utm_content = urlParams.get("utm_content");

    const utm_campaign =
      "de_jlr_jlr_nv_all_all_my24_gmc1000_s_thk_mar_may_fy2324q4_dis_04235780_dg_trf_3067515_mobilecoop";
    const utm_term =
      "aff_Luxury Automotive_mobile_i_mobilecoop_dg_des_600x312_nu_ros_far_de_de_dis_04235781_jlr_3067515_mobilecoop";

    // setting model index or default value
    if (modelId) {
      setModelIndex(modelId);
    } else {
      setModelIndex("2");
    }

    if (utm_source) {
      setUtmSource(utm_source);
    }

    if (utm_medium) {
      setUtmMedium(utm_medium);
    }

    if (utm_campaign) {
      setUtmCampaign(utm_campaign);
    }

    if (utm_term) {
      setUtmTerm(utm_term);
    }

    setDealerIndex(dealerId);
    setLink(
      `https%3A%2F%2Flandrover.mobile.de%2F%3FmodelId%3D${modelId}%26dealerId%3D${dealerId}`
    );
    //console.log(dealerId);

    // get model info
    if (modelId !== null) {
      if (models[modelId]) {
        setModelName(models[modelId]?.modelName);
        setIntro(models[modelId]?.intro);
        setFeatures(models[modelId]?.features);
        setSeats(models[modelId]?.seats);
        setMotorization(models[modelId]?.motorization);
        setCarBody(models[modelId]?.carBody);
        setConsumptionValues(models[modelId]?.consumptionValues);
        setPrice(models[modelId]?.price);
        setImages(models[modelId]?.images);
      }
    }

    // get dealer info
    if (dealerId !== null || dealerId !== undefined) {
      if (dealers[dealerId]) {
        setDealership(dealers[dealerId]?.dealership);
        setDealerAddress(dealers[dealerId]?.dealerAddress);
        setContact1(dealers[dealerId]?.contact1);
        setClickUrlDealer(dealers[dealerId]?.clickUrlDealer);
      }
    }

    // create utm_content --- OLD VERSION
    // const lowercaseModel = modelName.replace(/\s+/g, "").toLowerCase();
    // const deviceWidth = window.innerWidth;
    // let device;

    // if (deviceWidth < 576) {
    //   device = "mobile";
    // } else {
    //   device = "desktop";
    // }

    // setUtmContent(`${lowercaseModel + device}`);
  }, []);

  useEffect(() => {
    switch (modelIndex) {
      case "0":
        setClickUrlModel("modelName=Range%20Rover%20Evoque");
        setUtmContent("range_rover_evoque");
        break;
      case "1":
        setClickUrlModel("modelName=Range%20Rover%20Velar");
        setUtmContent("range_rover_velar");
        break;
      case "2":
        setClickUrlModel("modelName=Discovery");
        setUtmContent("discovery");
        break;
      case "3":
        setClickUrlModel("modelName=Discovery%20Sport");
        setUtmContent("discovery_sport");
        break;
      case "4":
        setClickUrlModel("modelName=Defender");
        setUtmContent("defender");
        break;
      case "5":
        setClickUrlModel("modelName=Range%20Rover%20Sport");
        setUtmContent("range_rover_sport");
        break;
      default:
        setClickUrlModel("");
        break;
    }
  }, [modelIndex]);

  // effect for page view
  useEffect(() => {
    window.mobile.consent.helpers
      .getAnalyticsPermissions()
      .then(({ isAnalyticsAllowed }) => {
        if (isAnalyticsAllowed) {
          window.dataLayer.push({
            environment: "production",
            event: "pageMeta",
            ga_property: "Desktop",
            page_type: "LandroverLandingPage",
            selected_language: "de",
          });

          window.dataLayer.push({
            click_source_domain: document.referrer,
            event: "page_view",
            non_interaction: true,
            page_type: "LandroverLandingPage",
          });
        }
      });
  }, []);

  return (
    <div
      className={`${
        darkMode ? `${styles.darkMode} ${btnStyles.darkMode}` : null
      }`}
    >
      <div className={`${styles.app}`}>
        <Header darkMode={darkMode} />
        <Impressions
          images={images}
          modelIndex={modelIndex}
          darkMode={darkMode}
          modelName={modelName}
        />
        <VehicleInfo
          darkMode={darkMode}
          dealerId={dealerIndex}
          clickUrlDealer={clickUrlDealer}
          clickUrlModel={clickUrlModel}
          link={link}
          modelName={modelName}
          intro={intro}
          features={features}
          seats={seats}
          motorization={motorization}
          carBody={carBody}
          consumptionValues={consumptionValues}
          price={price}
          utmMedium={utmMedium}
          utmSource={utmSource}
          utmCampaign={utmCampaign}
          utmTerm={utmTerm}
          utmContent={utmContent}
        />
        <DealerInfo
          dealerId={dealerIndex}
          dealership={dealership}
          dealerAddress={dealerAddress}
          modelName={modelName}
          contact1={contact1}
          clickUrlDealer={clickUrlDealer}
          clickUrlModel={clickUrlModel}
          link={link}
          utmMedium={utmMedium}
          utmSource={utmSource}
          utmCampaign={utmCampaign}
          utmTerm={utmTerm}
          utmContent={utmContent}
        />
        <footer>
          <div className="footerContainer">
            <ul>
              <a
                href="https://www.mobile.de/service/imprint"
                target="_blank"
                rel="noreferrer"
              >
                <li>Impressum</li>
              </a>
              <a
                href="https://www.mobile.de/careers/"
                target="_blank"
                rel="noreferrer"
              >
                <li>Careers (English)</li>
              </a>
              <a
                href="https://www.mobile.de/service/agbIndex"
                target="_blank"
                rel="noreferrer"
              >
                <li>AGB</li>
              </a>
              <a
                href="https://www.mobile.de/service/privacyPolicy"
                target="_blank"
                rel="noreferrer"
              >
                <li>Datenschutz</li>
              </a>
              <a
                href="https://www.mobile.de/consent/"
                target="_blank"
                rel="noreferrer"
              >
                <li>Datenschutzeinstellungen</li>
              </a>
              <a
                href="https://www.mobile.de/magazin/artikel/kontakt-41622"
                target="_blank"
                rel="noreferrer"
              >
                <li>Kontakt</li>
              </a>
            </ul>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
